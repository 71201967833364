.cv_searchbar_container
{
    background-color: rgb(219,235,211);
    color :rgb(23,56,61);


    border-radius: 10px;
}
.mr_filter_container
{   
   
    justify-content: space-between;
    display: flex;
    padding: 10px 10px 10px;
    border-radius: 10px;
   
}
.mr_filter_form_container
{

   padding: 15px;

   justify-content: space-between;
   width: 100%;

}
.mr_filter_form_container>div
{
    flex: 0.2;
}
.cv_searchbar
{
    height: 50px;
    width: 100%;
    outline: none;
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    border: none;
    border-bottom: 0.2px solid lightgray;
    margin-bottom: 20px;
}
.search_button_container
{
  flex:0.2 ;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

    
}
.export_button_container
{
    display: flex;
    
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    
}
.add_cashier_button
{
    color: black;
    flex: 0.3;
    background-color: yellow;
    padding: 10px;
    margin: 5px 0px;
    border: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    transition: ease-in-out;
}
.filter_search_button:hover
{
    color:white !important;
    background-color: green !important;
    scale:110%

}
.filter_search_button
{
    color: white;
    background-color: rgb(23,56,61);;
    justify-content: center;
    padding: 10px;
    margin: 5px 0px;
    border: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    width: 90%;
    transition: ease-in-out;

}
.refresh_button
{
    color: white;
    
    background-color: rgb(33, 146, 190) !important;
    padding: 5px 15px;
    margin: 5px 0px;
    border: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    transition: ease-in-out;
}
.export_button
{
    color: white;
    
    background-color: blue !important;
    padding: 5px 15px;
    margin: 5px 0px;
    border: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    transition: ease-in-out;
}
.export_button:hover
{
    color:black !important;

    scale:110%

}
.table_format
{
    max-height: 550px !important;
    overflow-y:scroll;
    scrollbar-width:thin;
    scrollbar-gutter: stable both-edges;
}
.print_icon
{
    /* background-color: green !important; */
    color: green !important;
   border-radius: 5px;
   font-size: 25px !important;
   transition: 0.5 ease-in-out;
   cursor: pointer; 
   margin: 0px 10px;
}
.print_icon:Hover
{
    font-size: 28px !important;
    background-color: green !important;
    color: white!important;
    
}
.delete_icon
{
    color: red !important;
    border-radius: 5px;
    font-size: 25px !important;
    transition: 0.5 ease-in-out;
    cursor: pointer; 
    margin: 0px 10px;
}
.delete_icon:hover
{
    font-size: 28px !important;
    background-color: red !important;
    color: white!important;
} 