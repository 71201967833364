.top_title_container
{
    padding: 10px;
    display: flex;
    background-color: rgb(219,235,211);
    text-align: start;
    border-radius: 10px;
    color: rgb(42,180,232);
}
.default_color_container
{
    background-color: rgb(219,235,211);
    color:rgb(23,56,61);
}
 
.selection_details
{
    margin-top: 5px;
   
    padding: 3px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    background-color: white;
    width: 100%;
    text-align: center;
    color:rgb(23,56,61);
}
.selection_details>span{
    box-sizing: border-box;
    background-color: green;
    padding: 0px 10px;
    border-radius: 3px;
    width: 100%;
    color:white
}