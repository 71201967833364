.mc_title_container
{
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: rgb(219,235,211);
    text-align: start;
    border-radius: 10px;
    color: rgb(23,56,61);
    margin-bottom: 20px;
}
.cv_searchbar_container
{
    background-color: rgb(219,235,211);
    padding: 15px 20px;
    width: 100%;
    border-radius: 10px;
}
.cv_searchbar
{
    height: 50px;
    border-radius: 8px;
    padding: 5px 10px;
    width: 100%;
    outline: none;
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    border: none;
    border-bottom: 0.2px solid lightgray;
    margin-bottom: 20px;
}
.add_cashier_button_container
{
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}
.add_cashier_button
{
    color: white;
    background-color:rgb(23,56,61);
    padding: 10px;
    margin: 5px 0px;
    border: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    transition: ease-in-out;
}
.add_cashier_button:hover
{
    color:white !important;
    background-color: green !important;
    scale:110%

}
.export_button
{
    color: white;
    
    background-color: blue !important;
    padding: 5px 15px;
    margin: 5px 0px;
    border: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    transition: ease-in-out;
}
.export_button:hover
{
    color:black !important;

    scale:110%

}
.form-label
{
    font-size: 16px;
    font-weight: 600;
}
.form-control
{
font-size: 16px;
    font-weight: 600;
}
.register
{
    font-size: 16px;
    margin: 10px auto;
    width: 95%;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 4px;
    border: none;
    color :white;
    background-color: rgb(23,56,61);;
    
    padding: 5px;
    transition: 0.2sec ease-in-out;
}
.register:hover
{
    font-size: 17px;
    width: 100%;
    font-weight: 700;
    background-color:rgb(104,159,57) ;
   
}

/* modal css */
.modal_color
{
    background-color: rgb(219,235,211);
    color:rgb(23,56,61);
}
