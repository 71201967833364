.header_title
{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    width:100%;
    color: rgb(23,56,61);
   
}
.title_container
{
 display: flex;
 justify-content: space-between;
 align-items: center;
 
}
.title_container>div
{
    display: flex;
    flex-direction: column;
}
.title_container>div>h6
{
    width: 100%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    color: rgb(23,56,61);
    font-size: 14px;
}
.title_container>div>h5
{
    width: 100%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    color: gray;
    font-size: 14px;
}