.app_contianer
{
    display: flex;
    flex-direction: row;
    height: 100%;
    
}
.app_sidebar
{
    display: flex;
   
    flex-direction: column;
    position: sticky;
    padding: 10px;
    max-width: 230px;
    background-color: rgb(219,235,211);
    width: 100%;
    height:100vh;
}
.top_section
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sidebar_logo
{
    width: 100px;
    object-fit: contain;
}
.nav_section
{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

}
.nav_options
{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    text-decoration: none;
    color: rgb(23,56,61);
    margin:10px auto;
    transition: 0.3s ease-in-out;
    border-radius: 3px;
 

}
.nav_options_text{
    display: grid;
    place-items:start;
   
    height: 100%;
    flex: 0.7;
    

}
.nav_options_text>h2
{
    font-size: 14px;
    margin: auto 0px;
  
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.nav_icons
{
    flex:0.3;
    display: grid;
    place-items: center;
    justify-content: center;
    color:  rgb(23,56,61);;
   
    /* font-size: 30px !important; */

}
.bars{
    font-size: 40px !important;
    color: rgb(23,56,61);
}
.nav_options:hover>.nav_icons
{
    color: white;
}
.nav_options:hover
{
    background-color: rgb(26, 131, 87);
    color: white;
    font-weight: 800;
}
.nav_options:hover>.nav_options_text>h2
{
    font-size: 14px;
    font-weight: 700;
}

.bars:hover
{
    background-color: rgb(26, 131, 87);
    color: white;
    font-weight: 800;
    border-radius: 4px;
}
.content-container
{
    width: 90%;

}
 .username_container
 {
    display :flex;
    justify-content: space-around;
    align-items: center;
 
 }
 .us_container
 {
    display: flex;
    flex-direction: column;
    align-items: center;
 }
 .us_container>h6
 {
    font-size: 14px;
    color: black;
 }
 .us_container>h5
 {
    font-size: 12px;
    color:gray;
 }
 .sign_out{

    font-size: 30px !important;
    color:rgb(42,180,232);
    padding: 5px;
 }
 .sign_out:hover
 {
    background-color: lightgray;
    color: rgb(235, 122, 122);
    border-radius: 4px;
 }